// react/gatsby imports
import React from "react";
// assets
import ogImage from "assets/images/seo/airy-layout.jpg";
import ogImageVK from "assets/images/seo/vk/airy-layout.jpg";
// data
import localData from "data/pages/airy-layout";
// hooks
import useAiryLayoutPortfolio from "hooks/portfolio/useAiryLayoutPortfolio";
import useAiryLayoutQuery from "hooks/graphql/useAiryLayoutQuery";
// utilities
import generateCanonicalLink from "utilities/link-utils";
// constants
import PAGE_SLUGS from "constants/page-slugs";
// components
import Layout from "components/layout";
import SEO from "components/layout/seo";
import MasterClassStream from "components/pages/course-page/master-class-stream";
import MasterClassContent from "components/pages/course-page/master-class-content";

const pageStype = `
  .header-logo {
    position: relative;
    top: -0.5vw;
  }

  @media only screen and (max-width: 575px) {
    .header-logo {
      top: -1vw;
    }
  }
`;

const description = `
  Воздушные композиции (Airy Layout) — чистый минимализм. 
  Узнайте, почему полупустые макеты работают. Почему это осмысленное пространство. Ювелирность и театральность.
`;

const AiryLayoutPage = () => {
  const prismicData = useAiryLayoutQuery();
  const portfolio = useAiryLayoutPortfolio();
  const { data } = prismicData.course;
  const { data: seoData } = prismicData.page || {};
  const { data: indexSeoData } = prismicData.indexPage || {};
  return (
    <Layout logo="airy-layout" pageStyle={pageStype}>
      <div className="section-top-block" />
      <SEO
        title="Графсистема Воздух"
        description={
          seoData?.seodescription?.text ||
          indexSeoData?.seodescription?.text ||
          description
        }
        keywords={[
          "layout",
          "airy",
          "графсистема",
          "гранич",
          "granich",
          "воздух",
        ]}
        ogImage={seoData?.seoimage?.url || ogImage}
        ogImageVk={seoData?.seoimage?.thumbnails?.vk?.url || ogImageVK}
        url={generateCanonicalLink(PAGE_SLUGS.graphicSystemAiryLayout)}
      />
      <MasterClassContent
        courseOffer={{ data }}
        courseExplanations={{ data: localData.AiryExplanations }}
        courseTitle={data.coursetitle?.text}
        coursePortfolio={{
          pinterestText: "Воздух в Энциклопедии\nГрафдизайна Школы",
          pinterestLink: "https://www.pinterest.ru/vadim_granich/airy-layout/",
          portfolio,
          graphicSystemName: "Воздух",
        }}
        coursePriceRange={{
          formId: "ltForm3153227",
          priceRange: localData.priceRange,
          formPostUrl:
            "https://school.granich.design/pl/lite/block-public/process-html?id=1453559711",
        }}
      />
      <MasterClassStream
        title="Учебный стрим по системе Воздуха"
        description="По этой графсистеме на ютуб-канале Школы есть учебный стрим.
        Его можно посмотреть для разогрева. Если вам он понравится, то смело берите этот мастер‑класс."
        youtubeUrl="https://www.youtube.com/embed/EhvjOB8rz9s"
      />
    </Layout>
  );
};

export default AiryLayoutPage;
